.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  font-size: 28px;
  font-family: Arial, Helvetica, sans-serif;
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../src/assets/images/bg-0.png");
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-attachment: fixed;
}

.header {
  /* padding: 30px; */
  /* text-align: center; */
}

#navbar {
  overflow: hidden;
  background-color: rgba(46, 49, 146, 0.5);
  z-index: 1;
}

#navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 5px 16px;
  text-decoration: none;
  font-size: 17px;
}

#navbar a:hover {
  border-bottom: white 5px solid;
}

#navbar a.active {
  border-bottom: white 5px solid;
  color: white;
}

.content {
  padding: 16px;
}

.sticky {
  background-color: rgba(46, 49, 146, 1) !important;
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 60px;
}

.nav-container{
  margin: 0 auto;
  width: max-content;
}

.title { 
  font-family: "Bodoni MT", Didot, "Didot LT STD", "Book Antiqua", Garamond, "Times New Roman", serif;
  font-size: 55px;
  text-align: center; 
  font-style: normal; 
  font-variant: normal; 
  font-weight: 500; 
  line-height: 26px; 
  color: white;
  margin-top: 30px;
  margin-bottom: 30px;
} 

.sub-title { 
  margin: 0 auto;
  width: 700px;
  font-family: "Bodoni MT", Didot, "Didot LT STD", "Book Antiqua", Garamond, "Times New Roman", serif;
  font-size: 25px;
  text-align: justify; 
  font-style: normal; 
  font-variant: normal; 
  font-weight: 500; 
  color: white;
  line-height: 30px; 
} 

.home-tab{
  background-color: rgba(46, 49, 146, 0.7);
  width: 190px;
  height: 200px;
  text-align: center;
  color: white;
  font-size: 20px;
  padding-top: 25px;
}
.home-tab:hover{
  background-color: rgba(46, 49, 146, 1);
  border: white 2px solid;
}

.home-icon{
  margin: 10px;
}

#home, #contact{
  height: 100vh;
  /* background-image: url("../src/assets/images/bg-0.png");
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: 100% auto; */
}

#about{
  margin-top: 550px;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: white;
}

#events{
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: white;
}

#contact{
  margin-top: 150px;
}

.text{
  color: rgba(46, 49, 146);
  text-align: justify;
}

.center{
  text-align: center !important;
}

.right{
  text-align: right !important;
}

.contact-title{
  font-family: "Myriad Web Pro", "Myriad Pro", Myriad, "Segoe UI", "Verdana", "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-align: right;
  color: white;
}

.contact-text{
  font-family: "Myriad Web Pro", "Myriad Pro", Myriad, "Segoe UI", "Verdana", "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: white;
}

input, textarea {
  width: 100%;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: transparent;
  border: white 2px solid;
  color: white;
}

::placeholder {
  opacity: 0.9;
}

.invisible-button{
  margin-top: unset !important;
  border: none !important; 
  padding: unset !important;
  font-size: unset !important;
  border-bottom: unset !important;
}

button{
  background-color: transparent;
  color: white;
  width: fit-content !important;
  margin-top: 20px !important;
  border: none !important; 
  padding: 2px !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  border-bottom: white 2px solid !important;
}

button:hover{
  border: white 2px solid !important;
}

.boxElement{
  margin-top: 50px;
  background-color: rgba(46, 49, 146, 0.5);
  width: 100%;
  height: 80px;
}

.events-img-container{
  height: 250px;
  overflow: hidden;
}

.events-img{
  max-width: 100%;
}

.logo{
  padding: 20px;
  width: 200px;
}

.link{
  color: white !important;
  text-decoration: underline !important;
}
.link:hover{
  font-weight: 700 !important;
}

.curve{
  width: 100%; 
  margin-top: -450px;
}

.about-container, .events-container{
  margin-top: -150px;
}


@media only screen and (max-width: 800px) { 
  body {
    background-size: auto 100%;
  }

  .logo{
    width: 100px;
  }
  
  #navbar a {
    padding: 1px 8px;
    font-size: 10px;
  }

  .title { 
    font-size: 25px;
  } 
  
  .sub-title { 
    width: 100%;
    font-size: 14px;
    text-align: center;
  } 

  .home-tab{
    width: 70%;
    height: 120px;
    font-size: 15px;
    padding-top: 0px;
    margin: 8px;
  }

  .home-icon{
    width: 70%;
    padding: 15px;
    margin: unset;
  }

  #about{
    margin-top: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #events{
    margin-top: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .curve{
    width: 100%; 
    margin-top: -115px;
  }

  .about-container, .events-container{
    margin-top: 0px;
  }

  .contact-title{
    font-size: 30px;
    text-align: center;
  }
  
  .contact-text{
    text-align: center !important;
    font-size: 15px;
    width: 100% !important;
  }

  .boxElement{
    display: none;
  }

  .events-img-container{
    height: unset;
    overflow: unset;
  }

  .text{
    font-size: 15px;
  }

  input, textarea {
    width: 100%;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
  }

  button{
    font-size: 14px !important;
  }
  
}

@media only screen and (max-width: 320px) { 
  #about, #events{
    margin-top: 385px !important;
  }
}